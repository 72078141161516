import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { PageHeader } from '../../components/page-components/page-header/page-header';
import {
  CONSOLE_MAIN_TABLE,
  CONSOLE_PAGE_HEADER,
} from '../../constants/test-ids';
import { TableComponent } from '../../components/table/table-component/table-component';
import { ReactComponent as IdentitiesIcon } from 'assets/icons/identities.svg';
import { tableStructure } from './constants';
import { ApiContext } from '../../components/providers/api-provider/api-provider';
import { ContainerWithLoader } from '../../components/container-with-loader/container-with-loader';
import { GraphRenderer } from './cell-renderer';

export const ConsoleAudit = () => {
  const apiContext = useContext(ApiContext);

  const { data, isLoading, isError } = apiContext.baseApi?.useGetUserLogQuery();

  return (
    <ContainerWithLoader isLoading={isLoading} isError={isError}>
      <PageHeader
        title="Console Audit/Activity Log"
        dataTestId={CONSOLE_PAGE_HEADER}
      >
        <IdentitiesIcon />
      </PageHeader>
      <Box>
        <TableComponent
          isListLoading={isLoading}
          isListErrored={isError}
          isEmptyList={data?.triggered_by?.length === 0}
          isListFetching={isLoading}
          dataTestId={CONSOLE_MAIN_TABLE}
          list={data?.triggered_by}
          tableColumns={tableStructure}
          notPaginated
          extraCellsRenderObj={GraphRenderer}
        />
      </Box>
    </ContainerWithLoader>
  );
};
