import { FilterItem } from 'components/multi-filter/multi-filter';
import { TableColumnProps } from 'components/table/table-component/table-component';

export const tableStructureWithLogin: TableColumnProps[] = [
  {
    id: 'selection',
    title: '',
    position: 'left',
  },
  {
    id: 'user_id',
    title: 'Identity',
    position: 'left',
    withSort: true,
  },
  {
    id: 'overprivilege_score',
    title: 'Over Priv. Score',
    position: 'left',
  },
  {
    id: 'inferred_owner',
    title: 'Owner',
    position: 'left',
  },
  {
    id: 'all_permissions',
    title: 'Permissions',
    position: 'left',
  },
  {
    id: 'last_action',
    title: 'Last Accessed',
    position: 'left',
    isDate: true,
  },
];

export const AssetContributorsFilterList: FilterItem[] = [
  {
    title: 'Is owner',
    filterId: 'only_owners',
    filterColumns: ['user_id'],
    props: [
      {
        label: 'True',
        value: true,
      },
      {
        label: 'False',
        value: false,
      },
    ],
  },
  {
    title: 'Is overprivilege',
    filterId: 'only_overprivilege',
    filterColumns: ['user_id'],
    props: [
      {
        label: 'True',
        value: true,
      },
      {
        label: 'False',
        value: false,
      },
    ],
  },
];
