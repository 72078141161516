import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { emailPattern } from 'utils/general/reg-exp-patterns';
import {
  closeModalByNameAction,
  PlainModals,
} from 'store/modules/modals/modals.reducer';
import { ApiContext } from 'components/providers/api-provider/api-provider';
import { ToastMessage } from 'components/toast/toast-message';
import {
  useGetModalMetaData,
  useIsPlainModalOpenSelector,
} from 'store/modules/modals/modals.selector';
import { StyledInput } from 'components/table/styles';
import { StyledFormControl } from 'pages/connectors/components/connector-details/components/inputs-schemas/styles';
import { ModalContainer } from 'components/modals/modal-container/modal-container';
import { FileType } from '../../../constant';

export const SendReportModal = () => {
  const dispatch = useDispatch();
  const apiContext = useContext(ApiContext);
  const isSendReportOpenModal = useIsPlainModalOpenSelector(
    PlainModals.SEND_REPORT_MODAL,
  );
  const metadata = useGetModalMetaData();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleCloseModal = () =>
    dispatch(closeModalByNameAction(PlainModals.SEND_REPORT_MODAL));

  const [sendReportPDF] =
    apiContext.baseApi?.useSendRiskAssessmentReportOnEmailMutation();

  const [sendReportCSV] = apiContext.baseApi.useSendCSVReportOnEmailMutation();

  const clickSendHandler = () => {
    handleSubmit(async (data) => {
      try {
        if (metadata.file_type === FileType.PDF) {
          await sendReportPDF(data.email).unwrap();
        }
        if (metadata.file_type === FileType.CSV && metadata.file_path) {
          await sendReportCSV({
            email: data.email,
            filePath: metadata.file_path,
          });
        }

        toast(
          <ToastMessage type="success" title="Report was sent successfully" />,
        );
      } catch (e) {
        toast(
          <ToastMessage type="error" title="Your request was unsuccessful" />,
        );
      } finally {
        handleCloseModal();
      }
    })();
  };

  return (
    <ModalContainer
      title="Email Report"
      isOpenModal={isSendReportOpenModal}
      handleCloseModal={handleCloseModal}
      handleConfirm={clickSendHandler}
      closeModalTitle="Cancel"
      confirmModalTitle="Send"
    >
      <StyledFormControl sx={{ width: '100%', marginTop: '40px' }}>
        <StyledInput
          autoComplete="off"
          placeholder="Enter Email"
          {...register('email', {
            required: true,
            pattern: emailPattern,
          })}
          error={errors.hasOwnProperty('email')}
        />
      </StyledFormControl>
    </ModalContainer>
  );
};
