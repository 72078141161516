import { ReactNode } from 'react';

import { ReactComponent as HealthOverviewIcon } from 'assets/icons/health-overview.svg';
// import { ReactComponent as RiskExplorerIcon } from 'assets/icons/risk-explorer.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';
//import { ReactComponent as ResourcesIcon } from 'assets/icons/resources.svg';
// import { ReactComponent as FeedbackSupportIcon } from 'assets/icons/feedback-support.svg';
// import { ReactComponent as DocumentationIcon } from 'assets/icons/documentation.svg';
//import { ReactComponent as OpenSourceDependenciesIcon } from 'assets/icons/open-source-dependencies.svg';
import { ReactComponent as ReportingIcon } from 'assets/icons/reporting.svg';
import { ReactComponent as IdentityIcon } from 'assets/icons/identities.svg';
import { ReactComponent as PolicyBuilderIcon } from 'assets/icons/policies.svg';
import { ReactComponent as KeyIcon } from 'assets/icons/code-governance.svg';
import { ReactComponent as AssetsIcon } from 'assets/icons/assets-menu.svg';
// import { ReactComponent as SignOutIcon } from 'assets/icons/sign-out.svg';
import { ReactComponent as ComplianceIcon } from 'assets/icons/compliance-icon.svg';
import { ReactComponent as RecommendationsIcon } from 'assets/icons/recommendations.svg';
import { paths } from 'constants/routes';
import {
  NAVIGATION_COMPLIANCE_BUTTON,
  NAVIGATION_DASHBOARD_BUTTON,
  NAVIGATION_FINDINGS_BUTTON,
  NAVIGATION_IAC_BUTTON,
  NAVIGATION_OPENSOURCE_BUTTON,
  NAVIGATION_POLICIES_BUTTON,
  //NAVIGATION_PR_SECRETS_BUTTON,
  NAVIGATION_RECOMMENDATIONS_BUTTON,
  NAVIGATION_REPORTING_ALERTS_BUTTON,
  NAVIGATION_REPORTING_BUTTON,
  NAVIGATION_REPORTING_HISTORY_BUTTON,
  NAVIGATION_REPORTS_BUTTON,
  NAVIGATION_RESOURCES_ASSETS_BUTTON,
  NAVIGATION_RESOURCES_BUTTON,
  NAVIGATION_RESOURCES_CONSOLE_BUTTON,
  //NAVIGATION_RESOURCES_GRAPH_BUTTON,
  NAVIGATION_RESOURCES_IDENTITIES_BUTTON,
  NAVIGATION_RESOURCES_TEAMS_BUTTON,
  NAVIGATION_SAST_BUTTON,
  NAVIGATION_SECRETS_BUTTON,
  NAVIGATION_SETTINGS_BUTTON,
  NAVIGATION_SETTINGS_CONNECTORS_BUTTON,
  NAVIGATION_SETTINGS_RECOMMENDATION_STATUS_BUTTON,
  NAVIGATION_SETTINGS_TENANTS_BUTTON,
  NAVIGATION_SETTINGS_USERMANAGEMENT_BUTTON,
  NAVIGATION_SETTINGS_WATCH_HIDE_BUTTON,
  // NAVIGATION_SIGNOUT_BUTTON,
  //NAVIGATION_TRIVY_BUTTON,
  RECOMMENDATIONS_ASSETS_TAB,
  RECOMMENDATIONS_IDENTITIES_TAB,
  RECOMMENDATIONS_TEAMS_TAB,
} from 'constants/test-ids';

export interface NavItemProps {
  id: string;
  path: keyof typeof paths;
  title: string;
  activePaths?: string[];
  children?: ChildNavItemProps[];
  isAdmin?: boolean;
  noConnectorEnabled?: boolean;
  icon: () => ReactNode;
  bottomItem?: boolean;
  dataTestId: string;
}

export interface ChildNavItemProps {
  id: string;
  path: keyof typeof paths;
  title: string;
  dataTestId: string;
  noConnectorEnabled?: boolean;
}

export const navItems: NavItemProps[] = [
  {
    id: 'dashboard',
    path: 'dashboard',
    title: 'Health Overview',
    dataTestId: NAVIGATION_DASHBOARD_BUTTON,
    icon: () => <HealthOverviewIcon />,
  },

  {
    id: 'policies',
    path: 'policies',
    title: 'Policies',
    dataTestId: NAVIGATION_POLICIES_BUTTON,
    icon: () => <PolicyBuilderIcon />,
  },

  {
    id: 'identities',
    title: 'Identity Governance',
    path: 'identities',
    activePaths: [paths.identities, paths.teams],
    dataTestId: NAVIGATION_RESOURCES_BUTTON,
    icon: () => <IdentityIcon />,
    children: [
      {
        id: 'identities',
        path: 'identities',
        title: 'Identities',
        dataTestId: NAVIGATION_RESOURCES_IDENTITIES_BUTTON,
      },
      {
        id: 'teams',
        path: 'teams',
        title: 'Teams',
        dataTestId: NAVIGATION_RESOURCES_TEAMS_BUTTON,
      },
    ],
  },

  {
    id: 'findings',
    title: 'Code Governance',
    path: 'secrets',
    dataTestId: NAVIGATION_FINDINGS_BUTTON,
    activePaths: [
      paths.opensourceDependencies,
      paths.secrets,
      paths.iac,
      paths.sast,
      paths.trivy,
      paths.prSecrets,
    ],
    icon: () => <KeyIcon />,
    children: [
      {
        id: 'opensourceDependencies',
        path: 'opensourceDependencies',
        title: 'Open Source',
        dataTestId: NAVIGATION_OPENSOURCE_BUTTON,
      },
      {
        id: 'secrets',
        path: 'secrets',
        title: 'Secrets',
        dataTestId: NAVIGATION_SECRETS_BUTTON,
      },
      {
        id: 'sast',
        path: 'sast',
        title: 'SAST Scan',
        dataTestId: NAVIGATION_SAST_BUTTON,
      },
      {
        id: 'iac',
        path: 'iac',
        title: 'IaC Terraform Scan',
        dataTestId: NAVIGATION_IAC_BUTTON,
      },
      // {
      //   id: 'trivy',
      //   path: 'trivy',
      //   title: 'Trivy PR Scan',
      //   dataTestId: NAVIGATION_TRIVY_BUTTON,
      // },
      // {
      //   id: 'pr-secrets',
      //   path: 'prSecrets',
      //   title: 'Trufflehog PR Scan',
      //   dataTestId: NAVIGATION_PR_SECRETS_BUTTON,
      // },
    ],
  },
  {
    id: 'assets',
    title: 'Assets',
    path: 'assets',
    dataTestId: NAVIGATION_RESOURCES_BUTTON,
    activePaths: [paths.assets, paths.artifactory],
    icon: () => <AssetsIcon />,
    children: [
      {
        id: 'assets',
        path: 'assets',
        title: 'Source Code Repositories',
        dataTestId: NAVIGATION_RESOURCES_IDENTITIES_BUTTON,
      },
      {
        id: 'artifactory',
        path: 'artifactory',
        title: 'Artifactory Repositories',
        dataTestId: NAVIGATION_RESOURCES_ASSETS_BUTTON,
      },
    ],
  },
  {
    id: 'reporting',
    path: 'alerts',
    title: 'Reporting',
    dataTestId: NAVIGATION_REPORTING_BUTTON,
    activePaths: [paths.alerts, paths.reporting, paths.reports],
    icon: () => <ReportingIcon />,
    children: [
      {
        id: 'reports',
        path: 'reports',
        title: 'System Reports',
        dataTestId: NAVIGATION_REPORTS_BUTTON,
      },
      {
        id: 'reporting',
        path: 'reporting',
        title: 'Report History',
        dataTestId: NAVIGATION_REPORTING_HISTORY_BUTTON,
      },
      {
        id: 'alerts',
        path: 'alerts',
        title: 'Alerts',
        dataTestId: NAVIGATION_REPORTING_ALERTS_BUTTON,
      },
    ],
  },
  {
    id: 'complianceOverview',
    path: 'complianceOverview',
    title: 'Compliance',
    dataTestId: NAVIGATION_COMPLIANCE_BUTTON,
    icon: () => <ComplianceIcon />,
  },
  {
    id: 'recommendations',
    title: 'Recommendations',
    path: 'recommendationsOverviewIdentity',
    dataTestId: NAVIGATION_RECOMMENDATIONS_BUTTON,
    activePaths: [
      paths.recommendationsOverviewIdentity,
      paths.recommendationsOverviewAsset,
    ],
    icon: () => <RecommendationsIcon />,
    children: [
      {
        id: 'recommendations-overview-identity',
        path: 'recommendationsOverviewIdentity',
        title: 'Identities',
        dataTestId: RECOMMENDATIONS_IDENTITIES_TAB,
      },
      {
        id: 'recommendations-overview-asset',
        path: 'recommendationsOverviewAsset',
        title: 'Assets',
        dataTestId: RECOMMENDATIONS_ASSETS_TAB,
      },
      {
        id: 'recommendations-overview-team',
        path: 'recommendationsOverviewTeam',
        title: 'Teams',
        dataTestId: RECOMMENDATIONS_TEAMS_TAB,
      },
    ],
  },

  {
    id: 'settings',
    path: 'userManagement',
    title: 'Settings',
    dataTestId: NAVIGATION_SETTINGS_BUTTON,
    noConnectorEnabled: true,
    isAdmin: true,
    activePaths: [
      paths.userManagement,
      paths.connectors,
      paths.tenantSettingsWatchHide,
      paths.console,
    ],
    icon: () => <SettingsIcon />,
    children: [
      {
        id: 'userManagement',
        path: 'userManagement',
        title: 'User Management',
        dataTestId: NAVIGATION_SETTINGS_USERMANAGEMENT_BUTTON,
        noConnectorEnabled: true,
      },
      {
        id: 'connectors',
        path: 'connectors',
        title: 'Connectors',
        dataTestId: NAVIGATION_SETTINGS_CONNECTORS_BUTTON,
        noConnectorEnabled: true,
      },
      {
        id: 'console',
        path: 'console',
        title: 'Console Audit/Activity Log',
        dataTestId: NAVIGATION_RESOURCES_CONSOLE_BUTTON,
      },
      {
        id: 'tenant-settings',
        path: 'tenantSettings',
        title: 'Tenant Settings',
        dataTestId: NAVIGATION_SETTINGS_TENANTS_BUTTON,
      },
      {
        id: 'tenantSettingsWatchHide',
        path: 'tenantSettingsWatchHide',
        title: 'Watch/Hide',
        dataTestId: NAVIGATION_SETTINGS_WATCH_HIDE_BUTTON,
      },
      {
        id: 'recommendationsStatus',
        path: 'recommendationsStatus',
        title: 'Recommendations Status',
        dataTestId: NAVIGATION_SETTINGS_RECOMMENDATION_STATUS_BUTTON,
      },
    ],
  },
  // {
  //   bottomItem: true,
  //   id: 'feedbackSupport',
  //   title: 'Feedback/Support',
  //   icon: () => <FeedbackSupportIcon />,
  // },
  // {
  //   id: 'documentation',
  //   title: 'Documentation',
  //   icon: () => <DocumentationIcon />,
  // },
  // {
  //   bottomItem: true,
  //   id: 'signout',
  //   title: 'Sign Out',
  //   path: 'signout',
  //   dataTestId: NAVIGATION_SIGNOUT_BUTTON,
  //   noConnectorEnabled: true,
  //   icon: () => <SignOutIcon />,
  // },
];
