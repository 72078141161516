import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import {
  closeModalByNameAction,
  PlainModals,
} from 'store/modules/modals/modals.reducer';
import { ApiContext } from 'components/providers/api-provider/api-provider';
import {
  useGetModalMetaData,
  useIsPlainModalOpenSelector,
} from 'store/modules/modals/modals.selector';
import { ToastMessage } from 'components/toast/toast-message';
import { ModalContainer } from 'components/modals/modal-container/modal-container';

export const RemoveUserModal = () => {
  const dispatch = useDispatch();
  const apiContext = useContext(ApiContext);
  const modalMetaData = useGetModalMetaData();
  const isInviteUserOpenModal = useIsPlainModalOpenSelector(
    PlainModals.REMOVE_USER_MODAL,
  );

  const handleCloseModal = () =>
    dispatch(closeModalByNameAction(PlainModals.REMOVE_USER_MODAL));

  const { useDeleteUserMutation } = apiContext.baseApi;
  const [deleteUser] = useDeleteUserMutation();

  const handleDeleteUser = async () => {
    try {
      await deleteUser(modalMetaData.userId!);
      dispatch(closeModalByNameAction(PlainModals.REMOVE_USER_MODAL));
      toast(
        <ToastMessage type="success" title="User was removed successfully" />,
      );
    } catch (error) {
      dispatch(closeModalByNameAction(PlainModals.REMOVE_USER_MODAL));
      toast(
        <ToastMessage type="error" title="Your request was unsuccessful" />,
      );
    }
  };
  return (
    <ModalContainer
      title="Are you sure you want to delete this user?"
      isOpenModal={isInviteUserOpenModal}
      handleCloseModal={handleCloseModal}
      handleConfirm={handleDeleteUser}
      confirmModalTitle="Yes"
      modalHeight="200px"
    />
  );
};
