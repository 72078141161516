import { TableColumnProps } from 'components/table/table-component/table-component';

export const tableStructure: TableColumnProps[] = [
  {
    id: 'package',
    title: 'Advisor',
    position: 'left',
  },
  {
    id: 'summary',
    title: 'Summary',
    position: 'left',
  },
  {
    id: 'severity',
    title: 'Severity',
    position: 'left',
  },
  {
    id: 'versions',
    title: 'Versions',
    position: 'left',
  },
];
