import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import {
  IdentitiesAccountStatus,
  IdentitiesAccountType,
} from '../../apis/types';
import { SortOrder } from 'utils/sort-order/sort';
import { Periods } from 'components/table/table-filters-components/date-picker/date-picker';
import dayjs from 'dayjs';

export interface IdentitiesState {
  accountType?: IdentitiesAccountType;
  accountStatus?: IdentitiesAccountStatus;
  activeSortedField: string;
  activeSortedOrder: SortOrder;
  userGroupsTeam?: Record<string, string>[];
  activityStartDate?: string;
  activityEndDate?: string;
  activityPeriod: string;
}

const initialState: IdentitiesState = {
  accountType: IdentitiesAccountType.total,
  activeSortedField: 'access_count',
  activeSortedOrder: SortOrder.Desc,
  activityPeriod: Periods[2],
  activityStartDate: dayjs(new Date())
    .subtract(1, 'months')
    .format('YYYY-MM-DDTHH:mm:ss'),
  activityEndDate: dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
};

export const identitiesSlice = createSlice({
  name: 'identities',
  initialState,
  reducers: {
    setAccountType: (
      state: IdentitiesState,
      action: PayloadAction<IdentitiesAccountType | undefined>,
    ) => {
      state.accountType = action.payload;
    },
    setAccountStatus: (
      state: IdentitiesState,
      action: PayloadAction<IdentitiesAccountStatus | undefined>,
    ) => {
      state.accountStatus = action.payload;
    },
    setActiveSortedField: (
      state: IdentitiesState,
      action: PayloadAction<string>,
    ) => {
      state.activeSortedField =
        action.payload || initialState.activeSortedField;
    },
    setActiveSortedOrder: (
      state: IdentitiesState,
      action: PayloadAction<SortOrder>,
    ) => {
      state.activeSortedOrder = action.payload;
    },
    setUserGroupsTeams: (
      state: IdentitiesState,
      action: PayloadAction<Record<string, string>[]>,
    ) => {
      state.userGroupsTeam = action.payload;
    },
    setActivityStartDate: (
      state: IdentitiesState,
      action: PayloadAction<string | undefined>,
    ) => {
      state.activityStartDate = action.payload;
    },
    setActivityEndDate: (
      state: IdentitiesState,
      action: PayloadAction<string | undefined>,
    ) => {
      state.activityEndDate = action.payload;
    },
    setActivityPeriod: (
      state: IdentitiesState,
      action: PayloadAction<string>,
    ) => {
      state.activityPeriod = action.payload;
    },
  },
});

export const {
  setAccountType,
  setAccountStatus,
  setActiveSortedField,
  setActiveSortedOrder,
  setUserGroupsTeams,
  setActivityStartDate,
  setActivityEndDate,
  setActivityPeriod,
} = identitiesSlice.actions;

export const identitiesReducer = identitiesSlice.reducer;
