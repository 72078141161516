import { Box } from '@mui/material';
import { PageHeader } from 'components/page-components/page-header/page-header';
import { ApiContext } from 'components/providers/api-provider/api-provider';
import {
  ExtraCellsObject,
  TableComponent,
} from 'components/table/table-component/table-component';
import {
  TRIVY_PAGE_HEADER,
  TRIVY_SCAN_MODAL_LINK,
  TRIVY_SCAN_TABLE,
} from 'constants/test-ids';
import { useGetResponseDataWithPagination } from 'hooks/use-get-response-data-with-pagination';
import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FiltersTableName } from 'store/modules/filters/filters.reducer';
import {
  setItemsPerPage,
  setSelectedTable,
} from 'store/modules/selected-column/selected-column.reducer';
import { useSelectedColumnSelector } from 'store/modules/selected-column/selected-column.selector';
import { tableStructure } from './constants';
import { TableCellWithLink } from 'components/table/table-components/table-components';
import { useNavigate } from 'react-router-dom';
import { paths } from 'constants/routes';
import dayjs from 'dayjs';

export const TrivyScan = () => {
  const apiContext = useContext(ApiContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { applicableFilters: tableFilters } = useSelectedColumnSelector();

  useEffect(() => {
    dispatch(setSelectedTable(FiltersTableName.TRIVY_PR_SCANS_TABLE));
    dispatch(setItemsPerPage(10));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const useLazyGetTrivyScanDataQuery =
    apiContext.baseApi.useLazyGetTrivyScanDataQuery;

  const {
    dataList: terraformCheckrunsList,
    isLoading,
    isError,
    isFetching,
    isNoFindings,
  } = useGetResponseDataWithPagination({
    baseApiCallback: useLazyGetTrivyScanDataQuery,
    tableFilters,
    itemsPerPage: 10,
  });

  const RenderRepoCell = (item: any) => {
    const repo = item.repository.split('/')[1] || item.repository;
    return repo;
  };

  const RenderScanDetails = (item: any) => {
    const repo = item.repository.split('/')[1] || item.repository;
    const openScanDetails = () => {
      navigate(
        `${paths.trivy}/${item.org}/${repo}/${item.pr_number}/${item.check_run_id}`,
      );
    };
    return (
      <TableCellWithLink
        handleClick={openScanDetails}
        isActive={true}
        itemTitle={item.check_run_id}
        dataTestId={TRIVY_SCAN_MODAL_LINK}
      />
    );
  };

  const extraCellsRenderObj: ExtraCellsObject = {
    repo: RenderRepoCell,
    check_run_id: RenderScanDetails,
    completed: (item: any) => dayjs(item?.completed).format('L LT'),
  };

  return (
    <Box>
      <PageHeader title="Trivy PR Scan" dataTestId={TRIVY_PAGE_HEADER} />
      <TableComponent
        isListLoading={isLoading}
        isListErrored={isError}
        isEmptyList={isNoFindings}
        isListFetching={isFetching}
        dataTestId={TRIVY_SCAN_TABLE}
        list={terraformCheckrunsList}
        tableColumns={tableStructure}
        extraCellsRenderObj={extraCellsRenderObj}
      />
    </Box>
  );
};
