import { useAppSelector } from '../../hooks';
import { SortOrder } from 'utils/sort-order/sort';

export const useActiveSortedFieldSelector = (): string =>
  useAppSelector(({ teams }) => teams.activeSortedField);

export const useActiveSortedOrderSelector = (): SortOrder =>
  useAppSelector(({ teams }) => teams.activeSortedOrder);

export const useTeamsSearchQuerySelector = (): string =>
  useAppSelector(({ teams }) => teams.searchQuery);

export const useActiveTabSelector = (): number =>
  useAppSelector(({ teams }) => teams.activeTab);
