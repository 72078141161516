import { useAppSelector } from '../../hooks';
import {
  IdentitiesAccountStatus,
  IdentitiesAccountType,
} from '../../apis/types';
import { SortOrder } from 'utils/sort-order/sort';

export const useAccountTypeSelector = (): IdentitiesAccountType | undefined =>
  useAppSelector(({ identities }) => identities.accountType);

export const useAccountStatusSelector = ():
  | IdentitiesAccountStatus
  | undefined => useAppSelector(({ identities }) => identities.accountStatus);

export const useActiveSortedFieldSelector = (): string =>
  useAppSelector(({ identities }) => identities.activeSortedField);

export const useActiveSortedOrderSelector = (): SortOrder =>
  useAppSelector(({ identities }) => identities.activeSortedOrder);

export const useIdentitiesUserGroupsTeamsSelector = ():
  | Record<string, string>[]
  | undefined => useAppSelector(({ identities }) => identities.userGroupsTeam);

export const useActivityStartDateSelector = (): string | undefined =>
  useAppSelector(({ identities }) => identities.activityStartDate);

export const useActivityEndDateSelector = (): string | undefined =>
  useAppSelector(({ identities }) => identities.activityEndDate);
