import { ModalContainer } from '../../../../components/modals/modal-container/modal-container';
import {
  useGetModalMetaData,
  useIsPlainModalOpenSelector,
} from '../../../../store/modules/modals/modals.selector';
import {
  closeModalByNameAction,
  PlainModals,
} from '../../../../store/modules/modals/modals.reducer';
import { useDispatch } from 'react-redux';
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  StyledTableBodyCell,
  StyledTableHeaderCell,
} from 'components/table/styles';
import dayjs from 'dayjs';
//@ts-ignore
import get from 'lodash.get';

const utcTimeRegExp =
  /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\\.[0-9]+)?(Z)?$/;

export const ViewInfoModal = () => {
  const dispatch = useDispatch();

  const { currentData, headerData } = useGetModalMetaData() as unknown as {
    currentData: any;
    headerData: any;
  };

  const isViewInfoModalOpen = useIsPlainModalOpenSelector(
    PlainModals.VIEW_INFO_MODAL,
  );

  const handleCloseModal = () => {
    dispatch(closeModalByNameAction(PlainModals.VIEW_INFO_MODAL));
  };

  const renderCellContent = (content: any, contentType: string) => {
    if (content === null || content === undefined) {
      return '';
    }
    if (contentType) {
      if (contentType === 'number' || contentType === 'int') {
        return content.toString();
      } else if (contentType === 'list') {
        return content.join(',');
      } else {
        return content.toString();
      }
    } else {
      if (typeof content === 'boolean') {
        return content.toString();
      }
      if (utcTimeRegExp.test(content)) {
        const date = dayjs(content);
        return date.format('L LT');
      }
      if (typeof content === 'number' && content.toString().length > 10) {
        return dayjs(content).format('L LT');
      }
    }

    return content.toString();
  };

  return (
    <ModalContainer
      isOpenModal={isViewInfoModalOpen}
      handleCloseModal={handleCloseModal}
      title={''}
      closeModalTitle={'Close'}
      minWidth="fit-content"
    >
      <Box>
        <TableContainer sx={{ maxHeight: '500px' }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                {headerData?.output_schema_names?.map(
                  (headCell: string, i: number) => (
                    <StyledTableHeaderCell key={`head_cell_${i}`}>
                      {headCell}
                    </StyledTableHeaderCell>
                  ),
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {[currentData].map((row: any, i: number) => (
                <TableRow key={`table_body_row_${i}`}>
                  {headerData?.output_schema?.map(
                    (item: string, index: number) => {
                      return (
                        <StyledTableBodyCell key={item}>
                          {renderCellContent(
                            get(row, item) !== undefined
                              ? get(row, item, '')
                              : '',
                            headerData?.output_schema_types?.length
                              ? headerData.output_schema_types[index]
                              : '',
                          )}
                        </StyledTableBodyCell>
                      );
                    },
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </ModalContainer>
  );
};
