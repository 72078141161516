import { InfoDetailsProps } from 'components/info-component/info-component';
import { TableColumnProps } from 'components/table/table-component/table-component';

export const tableStructureWithOrg: TableColumnProps[] = [
  {
    id: 'name',
    title: 'Team Name',
    withSort: true,
    position: 'left',
  },
  {
    id: 'team_risk_score',
    title: 'Risk Score',
    withSort: true,
    position: 'left',
  },
  {
    id: 'team_vulnerability_score',
    title: 'Vuln. Score',
    withSort: true,
    position: 'left',
  },
  {
    id: 'source',
    title: 'Source',
    withSort: true,
    position: 'left',
  },
  {
    id: 'org',
    title: 'Organization',
    withSort: true,
    position: 'left',
  },
  {
    id: 'num_total',
    title: 'Members',
    withSort: true,
    position: 'left',
  },
  {
    id: 'num_inactive',
    title: 'Inactive members',
    withSort: true,
    position: 'left',
  },
  {
    id: 'repos_count',
    title: 'Repositories',
    withSort: true,
    position: 'left',
  },
  {
    id: 'last_activity',
    title: 'Last Event',
    withSort: true,
    position: 'left',
    isDate: true,
  },
];

export const teamInfoStructure: InfoDetailsProps[] = [
  {
    id: 'name',
    title: 'Team',
  },
  {
    id: 'team_risk_score',
    title: 'Risk Score',
  },
  {
    id: 'average_vuln_per_repo',
    title: 'Avg Vuln. Per Repo',
  },
  {
    id: 'org',
    title: 'Organization',
  },
  {
    id: 'status',
    title: 'Status',
  },
  {
    id: 'total_repos',
    title: '# Repositories with access',
  },
  {
    id: 'identities',
    title: '# Identities',
  },
  {
    id: 'entity_graph',
    title: 'Graph',
  },
];
