import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export type Filter = Record<
  string,
  string | boolean | string[] | undefined | number | any[]
>;

export interface FilterColumnStore {
  previousColumn: string;
  previousTable: string;
  coordinates: { x: number; y: number };
  filteredColumn: string;
  tableFilterChips: Filter;
  applicableFilters: Filter;
  page: number;
  itemsPerPage: number;
  totalItems: number;
  highlightColumns: { id: string; title: string }[];
}

const initialState: FilterColumnStore = {
  previousTable: '',
  previousColumn: '',
  coordinates: { x: 0, y: 0 },
  filteredColumn: '',
  tableFilterChips: {},
  applicableFilters: {},
  page: 1,
  itemsPerPage: 10,
  totalItems: 0,
  highlightColumns: [],
};

export const selectedColumnSlice = createSlice({
  name: 'selected-column',
  initialState,
  reducers: {
    setSelectedColumn: (
      state: FilterColumnStore,
      action: PayloadAction<string>,
    ) => {
      state.previousColumn = action.payload;
    },
    setSelectedTable: (
      state: FilterColumnStore,
      action: PayloadAction<string>,
    ) => {
      state.previousTable = action.payload;
    },
    setColumnCoordinates: (
      state: FilterColumnStore,
      action: PayloadAction<{ x: number; y: number }>,
    ) => {
      state.coordinates = action.payload;
    },
    setHighlightColumns: (
      state: FilterColumnStore,
      action: PayloadAction<{ id: string; title: string }[]>,
    ) => {
      state.highlightColumns = action.payload;
    },
    setFilterChips: (
      state: FilterColumnStore,
      action: PayloadAction<Filter>,
    ) => {
      state.tableFilterChips = action.payload;
    },
    setApplicableFilters: (
      state: FilterColumnStore,
      action: PayloadAction<Filter>,
    ) => {
      state.applicableFilters = action.payload;
    },
    setPageNumber: (
      state: FilterColumnStore,
      action: PayloadAction<number>,
    ) => {
      state.page = action.payload;
    },
    setItemsPerPage: (
      state: FilterColumnStore,
      action: PayloadAction<number>,
    ) => {
      state.itemsPerPage = action.payload;
    },
    setTotalItems: (
      state: FilterColumnStore,
      action: PayloadAction<number>,
    ) => {
      state.totalItems = action.payload;
    },
  },
});

export const {
  setTotalItems,
  setPageNumber,
  setFilterChips,
  setItemsPerPage,
  setSelectedTable,
  setSelectedColumn,
  setHighlightColumns,
  setColumnCoordinates,
  setApplicableFilters,
} = selectedColumnSlice.actions;

export const selectedColumnReducer = selectedColumnSlice.reducer;
