import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { SortOrder } from 'utils/sort-order/sort';

export interface BranchesState {
  activeSortedField: string;
  activeSortedOrder: SortOrder;
}

const initialState: BranchesState = {
  activeSortedField: '',
  activeSortedOrder: SortOrder.Asc,
};

export const branchesSlice = createSlice({
  name: 'branches',
  initialState,
  reducers: {
    setActiveSortedField: (
      state: BranchesState,
      action: PayloadAction<string>,
    ) => {
      state.activeSortedField =
        action.payload || initialState.activeSortedField;
    },
    setActiveSortedOrder: (
      state: BranchesState,
      action: PayloadAction<SortOrder>,
    ) => {
      state.activeSortedOrder = action.payload;
    },
  },
});

export const { setActiveSortedField, setActiveSortedOrder } =
  branchesSlice.actions;

export const branchesReducer = branchesSlice.reducer;
