import React, { ReactNode } from 'react';
import { Modal, Box, Typography } from '@mui/material';

import { Colors } from 'constants/colors';
import { StyledButton } from '../../button/button';
import { FontSizes } from '../../../constants/font-sizes';
import { fontPrimary } from 'constants/font-family';
import { ReactComponent as CloseIcon } from 'assets/icons/cross-circle.svg';

interface Props {
  children?: ReactNode;
  title?: string;
  titleSize?: string;
  subTitle?: string;
  alignItems?: string;
  handleCloseModal?: () => void;
  handleConfirm?: () => void;
  modalWidth?: string;
  minWidth?: string;
  modalHeight?: string;
  isOpenModal: boolean;
  closeModalTitle?: string;
  confirmModalTitle?: string;
  backgroundColor?: string;
  left?: string;
  top?: string;
  disableConfirm?: boolean;
}

export const ModalContainer = ({
  children,
  title,
  titleSize,
  subTitle,
  alignItems,
  handleCloseModal,
  handleConfirm,
  isOpenModal,
  closeModalTitle,
  confirmModalTitle,
  modalHeight,
  modalWidth,
  minWidth,
  backgroundColor,
  left,
  top,
  disableConfirm,
}: Props) => {
  return (
    <Modal onClose={handleCloseModal} open={isOpenModal}>
      <Box
        sx={{
          position: 'absolute',
          top: top || '15%',
          left: left || '25%',
          width: modalWidth || '500px',
          minWidth: minWidth || '500px',
          boxShadow: 24,
          backgroundColor: backgroundColor || Colors.white,
          display: 'flex',
          flexDirection: 'column',
          alignItems: alignItems ? alignItems : 'center',
          padding: '20px 15px',
          borderRadius: '15px',
          maxHeight: modalHeight || '90vh',
          overflow: 'auto',
        }}
      >
        <Box width="100%" display="flex" justifyContent="space-between">
          <Box>
            <Box sx={{ textAlign: 'left', width: '100%' }}>
              {title && (
                <Typography
                  variant="h6"
                  sx={{
                    fontSize: titleSize || FontSizes.fontSize20,
                    fontWeight: 600,
                    fontFamily: fontPrimary,
                    color: Colors.blackPearl,
                  }}
                >
                  {title}
                </Typography>
              )}
              {subTitle && (
                <Typography
                  sx={{
                    fontSize: FontSizes.fontSize10,
                    color: Colors.darkGray,
                    fontFamily: fontPrimary,
                  }}
                >
                  {subTitle}
                </Typography>
              )}
            </Box>
          </Box>
          <Box display="flex" alignItems="center" sx={{ cursor: 'pointer' }}>
            {handleCloseModal && (
              <CloseIcon
                onClick={handleCloseModal}
                style={{
                  cursor: 'pointer',
                  width: '20px',
                  color: Colors.darkGray,
                }}
              />
            )}
          </Box>
        </Box>
        {children}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: 'auto',
            paddingTop: '15px',
            gap: '10px',
          }}
        >
          {handleCloseModal && (
            <StyledButton
              onClick={handleCloseModal}
              variant="outlined"
              color="secondary"
              size="large"
            >
              {closeModalTitle ? closeModalTitle : 'Cancel'}
            </StyledButton>
          )}
          {handleConfirm && (
            <StyledButton
              onClick={handleConfirm}
              variant="contained"
              color="secondary"
              size="large"
              disabled={disableConfirm}
            >
              {confirmModalTitle ? confirmModalTitle : 'Confirm'}
            </StyledButton>
          )}
        </Box>
      </Box>
    </Modal>
  );
};
