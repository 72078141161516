import { MutationTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import Box from '@mui/material/Box';
import { toast } from 'react-toastify';
import { ToastMessage } from '../toast/toast-message';
import React from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { SelectChangeEvent, Typography } from '@mui/material';
import { Colors } from 'constants/colors';
import { fontSecondary } from 'constants/font-family';
import { FontSizes } from 'constants/font-sizes';
import {
  VISIBILITY_HIDE_BUTTON,
  VISIBILITY_NONE_BUTTON,
  VISIBILITY_SHOW_BUTTON,
} from 'constants/test-ids';
import {
  StyledOptionItem,
  StyledSelect,
} from 'components/inputs/select/select';
import { CustomSvgIcon } from 'components/svg-icon/svg-icon';

interface Props {
  id: string;
  watched: boolean | null | undefined;
  hidden: boolean | null | undefined;
  switchWatched: MutationTrigger<any>;
  switchHidden: MutationTrigger<any>;
  disableTransform?: boolean;
  source?: any;
}

export const VisibilityButton = ({
  id,
  watched,
  hidden,
  switchWatched,
  switchHidden,
  source,
}: Props) => {
  console.log(source);
  const tabDetails = [
    {
      label: 'Hide',
      icon: (
        <VisibilityOff
          sx={{ width: '16px', height: '16px', color: Colors.darkGray }}
        />
      ),
      dataTestId: VISIBILITY_HIDE_BUTTON,
    },
    {
      label: 'None',
      icon: undefined,
      dataTestId: VISIBILITY_NONE_BUTTON,
    },
    {
      label: 'Show',
      icon: (
        <Visibility
          sx={{ width: '16px', height: '16px', color: Colors.darkGray }}
        />
      ),
      dataTestId: VISIBILITY_SHOW_BUTTON,
    },
  ];
  const getValue = (): number => {
    if (watched) return 2;
    if (hidden) return 0;
    else return 1;
  };

  const handleVisibility = (event: SelectChangeEvent) => {
    const value = parseInt(event.target.value);
    if (watched || value === 2) {
      try {
        // @ts-ignore
        source
          ? switchWatched({ id: id!, source: source })
          : switchWatched(id!);
      } catch (error) {
        toast(
          <ToastMessage type="error" title="Your request was unsuccessful" />,
        );
      }
    }
    if (hidden || value === 0) {
      try {
        source ? switchHidden({ id: id!, source: source }) : switchHidden(id!);
      } catch (error) {
        toast(
          <ToastMessage type="error" title="Your request was unsuccessful" />,
        );
      }
    }
  };

  return (
    <Box sx={{ gap: '5px' }}>
      <Typography
        sx={{
          color: Colors.darkGray,
          fontFamily: fontSecondary,
          fontSize: FontSizes.fontSize12,
          fontWeight: 500,
          mb: '5px',
        }}
      >
        Watch/Hide
      </Typography>
      <StyledSelect
        variant="outlined"
        color="info"
        size="medium"
        value={getValue()}
        //@ts-ignore
        onChange={handleVisibility}
        sx={{
          minWidth: '120px',
          width: 'auto',
          '& .MuiSelect-outlined': {
            color: `${Colors.blackPearl} !important`,
            WebkitTextFillColor: `${Colors.blackPearl} !important`,
          },
        }}
      >
        {tabDetails.map((tab, index) => (
          <StyledOptionItem value={index}>
            {tab.icon && (
              <CustomSvgIcon
                sx={{
                  fontSize: FontSizes.fontSize14,
                  width: '10px',
                  height: '10px',
                  marginRight: '6px',
                }}
              >
                {tab.icon}
              </CustomSvgIcon>
            )}
            {tab.label}
          </StyledOptionItem>
        ))}
      </StyledSelect>
    </Box>
  );
};
