import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { SortOrder } from 'utils/sort-order/sort';

export interface AssetsState {
  activeSortedField: string;
  activeSortedOrder: SortOrder;
  activePipelineActivityWorkflowId: number | null;
  activeDependencyPackage?: string;
  activeTab: number;
  activeFindingsTab: number;
}

const initialState: AssetsState = {
  activeSortedField: 'last_activity',
  activeSortedOrder: SortOrder.Desc,
  activePipelineActivityWorkflowId: null,
  activeTab: 0,
  activeFindingsTab: 0,
};

export const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    setActiveSortedField: (
      state: AssetsState,
      action: PayloadAction<string>,
    ) => {
      state.activeSortedField =
        action.payload || initialState.activeSortedField;
    },
    setActiveSortedOrder: (
      state: AssetsState,
      action: PayloadAction<SortOrder>,
    ) => {
      state.activeSortedOrder = action.payload;
    },
    setActivePipelineActivityWorkflowId: (
      state: AssetsState,
      action: PayloadAction<number | null>,
    ) => {
      state.activePipelineActivityWorkflowId = action.payload;
    },
    setActiveDependencyPackage: (
      state: AssetsState,
      action: PayloadAction<string>,
    ) => {
      state.activeDependencyPackage = action.payload;
    },
    setActiveTab: (state: AssetsState, action: PayloadAction<number>) => {
      state.activeTab = action.payload;
    },
    setActiveFindingsTab: (
      state: AssetsState,
      action: PayloadAction<number>,
    ) => {
      state.activeFindingsTab = action.payload;
    },
  },
});

export const {
  setActiveSortedField,
  setActiveSortedOrder,
  setActiveDependencyPackage,
  setActivePipelineActivityWorkflowId,
  setActiveTab,
  setActiveFindingsTab,
} = assetsSlice.actions;

export const assetsReducer = assetsSlice.reducer;
