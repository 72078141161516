export enum Colors {
  actionBorder = '#4F6C94',
  active = '#44985F',
  activeBorder = '#D6EADA',
  activeBg = '#F5FBF4',
  binge = '#2155F5',
  bingeTransparent05 = '#2155F580',
  black = '#000000',
  blackPearl = '#081B35',
  blue = '#4788C8',
  bodyBackground = '#F4F6F9',
  cardBackground = 'rgba(4, 20, 41, 0.45)',
  cellBorder = '#ECF1F8',
  chartBorder = '#293E5B',
  chipBorder = '#13444C',
  codeBackground = '#282c34',
  codeColor = '#61dafb',
  collapseBorder = '#E8EEFE',
  connectorAvatarBackground = '#041122',
  connectorBorderInactive = '#14202C',
  connectorButtonBackgroundInactive = '#2155F514',
  darkCyan = '#597275',
  darkDesaturatedCyan = '#365155',
  darkGray = '#6F8198',
  drawerBackground = '#041429',
  drawerHeader = '#0A203C',
  drawerSubSelection = '#102949',
  fadedBackground = '#020A15B2',
  green = '#00CA1E',
  greenBg = 'rgba(0, 202, 30, 0.2)',
  gridX = '#0F3135',
  gridY = '#6f8385',
  hovered = '#2155F5',
  hoveredBoxShadow = '#F2F5F9',
  innerCardBg = '#04142973',
  inactiveBorder = '#EAD6D6',
  inactiveBg = '#FBF4F4',
  integrationIcon = '#EDF1FE',
  kikorangiBlue = '#EDF1FE',
  lightBlue = '#97c2fc',
  lightGray = '#F2F2F2',
  lightGreen = '#74F257',
  lightPurple = '#99A1E4',
  lightRed = '#FF5A5E',
  lightSilver = '#EFEFEF',
  lightTiber = '#030F20',
  logoContainer = '#010A16',
  menuBorder = '#1B4F57',
  menuGradient = 'linear-gradient(90deg, #010A16 0%, #0B1EFF 192.14%)',
  menuIcon = '#EBF3FF',
  menuText = '#0B1EFF',
  orange = '#ff9d2b',
  plantation = '#234549',
  progressColor = '#308fe8',
  purple = '#5346F8',
  red = '#FF1B00',
  rulesBorder = '#04262B',
  sectionBackground = '#F4F6F9',
  sectionBorder = '#E6E8EB',
  sectionBoxShadow = '#051428',
  sectionDropShadow = 'rgba(7, 30, 45, 32)',
  selectionBoxBorder = '#95A1B2',
  subtext = '#DFE0E2',
  tabGradient = 'linear-gradient(95.01deg, #0B1EFF 0%, #1480FF 99.92%)',
  tableBorder = '#DFE4EC',
  tableCell = '#F9FBFE',
  tableHeader = '#F4F6F9',
  tableHover = '#E9EEFE',
  tiber = '#020A15',
  tooltipBg = '#020B25',
  tooltipBorder = '#1C314D',
  tooltipBox = '#061223',
  tooltipShadow = '#E1E7F0',
  transparent = 'transparent',
  white = '#fff',
  whiteTransparent03 = 'rgba(255, 255, 255, 0.3)',
  whiteTransparent04 = 'rgba(255, 255, 255, 0.4)',
  whiteTransparent05 = 'rgba(255, 255, 255, 0.5)',
  whiteTransparent07 = 'rgba(255, 255, 255, 0.7)',
  yellow = '#fdd60d',
  critical = '#FF5A5E',
  criticalBg = 'rgba(248, 84, 85, 0.2);',
  high = '#D62F33',
  highBg = 'rgba(247, 122, 99, 0.2)',
  medium = '#FF9C63',
  mediumBg = 'rgba(255, 157, 43, 0.2)',
  low = '#00D1FF',
  lowBg = 'rgba(253, 214, 13, 0.2)',
  info = '#CBD8E8',
}
