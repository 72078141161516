import { TableColumnProps } from '../../components/table/table-component/table-component';

export const tableStructure: TableColumnProps[] = [
  {
    id: 'source',
    title: 'Source',
    position: 'left',
  },
  {
    id: 'description',
    title: 'Description',
    position: 'left',
  },
  {
    id: 'owner',
    title: 'Organization',
    position: 'left',
  },
  {
    id: 'repository',
    title: 'Repository',
    position: 'left',
  },
  {
    id: 'author_email',
    title: 'User',
    position: 'left',
  },
  {
    id: 'severity',
    title: 'Severity',
    position: 'left',
  },
  {
    id: 'state',
    title: 'State',
    position: 'left',
  },
  {
    id: 'file',
    title: 'File',
    position: 'left',
  },
  {
    id: 'updated_at',
    title: 'Updated',
    position: 'left',
    isDate: true,
  },
];
